import React from 'react'
import SEO from '../components/seo'
import TransitionLink, { TransitionState } from 'gatsby-plugin-transition-link'
import {
  defaultPageTransition,
  defaultTransition,
} from '../utilities/animationHelpers'
import { motion } from 'framer-motion'

const variants = {
  enter: {
    opacity: 1,
    y: 0,
    transition: defaultTransition,
  },
  exit: {
    opacity: 0,
    y: 50,
    transition: defaultTransition,
  },
  hidden: {
    opacity: 0,
    y: -50,
    transition: defaultTransition,
  },
}

const BookCover = ({ data }) => {
  const { title, cover } = data.markdownRemark.frontmatter
  const { slug } = data.markdownRemark.fields

  return (
    <TransitionState>
      {({ transitionStatus }) => {
        return (
          <motion.div className='flex justify-center flex-wrap lg:items-center align-center content-center h-screen min-h-screen text-white'
            variants={variants}
            initial='hidden'
            animate={
              ['entering', 'entered', 'POP'].includes(transitionStatus)
                ? 'enter'
                : 'exit'
            }
          >
            <SEO title={`${title}`} />
            <img
              className='lg:h-almost-screen h-auto max-h-screen'
              src={cover}
              alt={`cover art of ${title}`}
            />
            <div
              className='lg:relative absolute lg:h-auto h-full lg:block flex flex-wrap content-center lg:ml-4 lg:flex-initial flex-100 text-center'
              style={{
                opacity: '90%',
              }}
            >
              <TransitionLink
                className='lg:block inline-block lg:flex-auto flex-100 mb-4 bg-blue-100 text-blue-400 py-4 px-6 rounded-lg shadow-lg sm:text-2xl text-xl'
                {...defaultPageTransition}
                to={`${slug}page-1`}
              >
                Start Reading
              </TransitionLink>
              <TransitionLink
                className='lg:block inline-block lg:flex-auto flex-100 bg-blue-100 text-blue-400 py-4 px-6 rounded-lg shadow-lg sm:text-2xl text-xl'
                {...defaultPageTransition}
                to={`${slug}`}
              >
                Book Index
              </TransitionLink>
            </div>
          </motion.div>
        )
      }}
    </TransitionState>
  )
}

export default BookCover

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        cover
        pages {
          content
          bgimage
          textimage
        }
      }
    }
  }
`
